import * as React from "react";
import {CreateColumn} from "./sfAnalytics";

declare let rnJQuery;
declare let smartFormsLoadedItems:any;
export class ReviewGenerator {

    public $refs:any;
    public Options:any;
    public formValues;
    public isUsingFileUploader;
    public ItemsToDisplay;
    public originalSendToSmartForms;
    public originalSaveComplete;
    public generator:any;
    public Initialized:boolean;
    public constructor(options)
    {
        this.Initialized=false;
        this.Options=options;
        console.log(this.Options);
    }

    public created(){

    }

    public FormSubmissionCompleted(){

    }

    public BeforeInitializingFieldData(){
        if(this.Initialized)
            return;
        this.Initialized=true;
        this.generator=this.Options.Generator;
        let extensions=this.Options.Generator.client_form_options.Extensions;

        if(typeof extensions.sf_summary =="undefined"||extensions.sf_summary.Enabled=='n')
            return;

        let options=extensions.sf_summary;
        console.log(options);


        this.$refs=rnJQuery(
            `<div ref="container" class="bootstrap-wrapper" style="padding:10px;border:#dfdfdf solid 1px;display:none;opacity:0;">
                <h1><i class="sfSummaryIcon" aria-hidden="true"></i><span class="sfSummaryTitle"></span></h1>
                <p class="sfSummaryDescription"></p>
                <table class="sfSummaryContainer">
                    
                </table>
                <div style="text-align: right;">
                    <button class="btn btn-danger sfSummaryEditButton"><i class="fa fa-pencil" aria-hidden="true" ></i> <span class="sfSummaryEditButtonText"></span></button>
                    <button class="btn btn-success sfSummarySubmitButton"><i class="fa fa-check" aria-hidden="true"></i> <span class="sfSummarySubmitButtonText"></span></button>
                </div>
            </div>`
        );

        this.$refs.insertAfter(this.generator.JQueryForm.parent());
        this.$refs.find('.sfSummaryTitle').text(' '+options.Title);
        this.$refs.find('.sfSummaryIcon').addClass(options.Icon);

        this.$refs.find('.sfSummaryDescription').text(options.Description);
        this.$refs.find('.sfSummarySubmitButtonText').text(options.SubmitText);
        this.$refs.find('.sfSummaryEditButtonText').text(options.EditText);
        this.$refs.find('.sfSummaryEditButton').click(()=>this.Edit());
        this.$refs.find('.sfSummarySubmitButton').click(()=>this.Submit());
        let formId=this.generator.form_id;
        let translations:any;


        this.originalSendToSmartForms=this.generator.SendToSmartForms;
        this.originalSaveComplete=this.generator.SaveCompleted;
        this.generator.SendToSmartForms=(formValues:any,isUsingFileUploader:any)=>{this.NewSendToSmartForms(formValues,isUsingFileUploader)};
        this.generator.SaveCompleted=(result:any,formValues:any)=>this.SaveComplete(result,formValues);
    }
    public Edit(){
        this.generator.JQueryForm.parent().find('input[type="submit"],.redNaoMSButton').removeAttr('disabled');
        rnJQuery(this.$refs).velocity({opacity:0},300,'easeOutExp',()=>{
            rnJQuery(this.$refs).css('display','none');
            this.generator.JQueryForm.parent().css('display','block').velocity({opacity:1},300,'easeInExp');
        });

    }

    private NewSendToSmartForms(formValues: any, isUsingFileUploader: any) {

        this.formValues=formValues;
        this.isUsingFileUploader=isUsingFileUploader;
        this.generator.JQueryForm.parent().velocity({opacity:0},300,'easeOutExp',()=>{
            this.ItemsToDisplay=[];
            this.generator.JQueryForm.parent().css('display','none');
            rnJQuery(this.$refs).css('display','block').velocity({opacity:1},300,'easeInExp');
            this.GenerateSummary(formValues);
        });

    }

    private GenerateSummary(formValues:any[]) {
        debugger;
        this.$refs.find('.sfSummaryContainer').empty();
        for(let fieldId in formValues)
        {
            let field=this.generator.FormElements.find((x:any)=>x.Id==fieldId);
            if(field==null||!field.StoresInformation())
                continue;

            if(field.Options.ClassName=="rednaorepeater")
            {
                for(let i=0;i<field.Fields.length;i++)
                {
                    let index=parseInt(field.Fields[i].Id.substring(field.Fields[i].Id.lastIndexOf('_')+1));
                    this.AddRow(field.Fields[i],formValues[fieldId].value[index],field.Fields[i].Options.Id,' #'+(index+1));
                }
            }
            else
                this.AddRow(field,formValues,fieldId);


        }
    }

    public Submit(){
        this.$refs.find('.sfSummarySubmitButton').RNWait('start');
        this.originalSendToSmartForms.call(this.generator,this.formValues,this.isUsingFileUploader);


    }

    private AddRow(field: any,formValues:any,fieldId:any, sufix:any='') {
        let columns=CreateColumn(field.Options);

        let text='';
        for(let column of columns)
        {
            if(text!='')
                text+= ' ';
            text+=column.formatter(null,null,formValues[fieldId]);


        }

        if(field.Options.ClassName=='sfFileUpload')
        {
            text='';
            let elements=field.JQueryElement.find('.sfUploadFilePath');
            for(let i=0;i<elements.length;i++)
            {
                let val=rnJQuery(elements[i]).val();
                if(val=='')
                    continue;

                if(text!='')
                    text+=',';

                text+=val.substring(val.lastIndexOf('\\')+1);
            }

        }

        if(text.trim()=='')
            return;


        let container=this.$refs.find('.sfSummaryContainer');

        let $row=rnJQuery('<tr></tr>');
        container.append($row);

        let labelTd=rnJQuery('<td style="width: 30%"></td>');
        $row.append(labelTd);
        labelTd.append(rnJQuery('<label></label>').text(field.Options.Label+sufix+":").addClass());

        let valueTd=rnJQuery('<td style="width: 100%"></td>');
        $row.append(valueTd);
        valueTd.append(rnJQuery('<label style="font-weight: normal;margin-right: 5px;"></label>').append(' '+text).addClass());

    }

    private SaveComplete(result: any, formValues: any) {
        this.$refs.find('.sfSummarySubmitButton').RNWait('stop');
        let refs=this.$refs;
        this.originalSaveComplete.call(this.generator,result,formValues);
        rnJQuery(this.$refs).velocity({opacity:0},300,'easeOutExp',()=>{
            rnJQuery(this.$refs).css('display','none');

            this.generator.JQueryForm.parent().css('display','block').velocity({opacity:1},300,'easeInExp');
        });



    }
}

declare let RedNaoEventManager:any;
RedNaoEventManager.Subscribe('GetClientExtension',function(data){

    data.Extensions.push( new ReviewGenerator(data));
});